<template>
    <div>
        <div class="tutorialsWrap">
            <button @click="tutorialsPopupHandle('jump-to-playlist')">
                <svg id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512"  xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g>
                        <g>
                            <path d="M265,96c65.3,0,118.7,1.1,168.1,3.3l0.7,0h0.7c23.1,0,42,22,42,49.1v1.1l0.1,1.1c2.3,34,3.4,69.3,3.4,104.9v0v0    c0.1,35.6-1.1,70.9-3.4,104.9l-0.1,1.1v1.1c0,13.8-4.7,26.6-13.4,36.1c-7.8,8.6-18,13.4-28.6,13.4h-0.8l-0.8,0    c-52.9,2.5-108.8,3.8-166.4,3.8c-3.5,0-7.1,0-10.6,0H256h-0.1c-3.6,0-7.2,0-10.8,0c-57.8,0-113.7-1.3-166.2-3.7l-0.8,0h-0.8    c-10.6,0-20.7-4.8-28.5-13.4c-8.6-9.5-13.4-22.3-13.4-36.1v-1.1l-0.1-1.1c-2.4-34.1-3.5-69.4-3.3-104.7v-0.1v-0.1    c-0.1-35.3,1-70.5,3.3-104.6l0.1-1.1v-1.1c0-27.2,18.8-49.3,41.9-49.3H78l0.7,0c49.5-2.3,102.9-3.3,168.2-3.3h9H265 M265,64    c-3,0-6,0-9,0s-6,0-9,0c-57.6,0-114.2,0.8-169.6,3.3c-40.8,0-73.9,36.3-73.9,81.3C1,184.4-0.1,220,0,255.7    c-0.1,35.7,0.9,71.3,3.4,107c0,45,33.1,81.6,73.9,81.6c54.8,2.6,110.7,3.8,167.8,3.8c3.6,0,7.3,0,10.9,0c3.6,0,7.2,0,10.7,0    c57.1,0,113-1.2,167.9-3.8c40.9,0,74-36.6,74-81.6c2.4-35.7,3.5-71.4,3.4-107.1c0.1-35.7-1-71.3-3.4-107.1c0-45-33.1-81.1-74-81.1    C379.2,64.8,322.7,64,265,64L265,64z"/>
                        </g>
                        <g>
                            <path d="M207,353.8V157.4l145,98.2L207,353.8z"/>
                        </g>
                    </g>
                </svg>
                Tutorials
            </button>
        </div>

        <!-- tutorials modal -->
        <div class="modalBg" :class="tutorialsPopup.isOpen ? 'modal-active' : ''">
            <div class="modalLayers" @click.prevent="tutorialsPopupHandle('close')"></div>
            <div class="modalBody">
                <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%">
                    <div class="productZoomImg">
                        <iframe ref="youtubeVideoFrame" width="100%" height="100%" src="https://www.youtube.com/embed/5r1lqqr5zkU?si=XG0b0UL19url0pk9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
                <div class="tutorialsModalBtnWrap">
                    <div class="btnLoaderCtm" v-if="tutorialsPopup.isDisableBtnLoading">
                        <div class="item loading-6">
                          <svg viewBox="25 25 50 50">
                            <circle cx="50" cy="50" r="20"></circle>
                          </svg>
                        </div>
                      </div>
                    <button @click="tutorialsPopupDisableHandle" v-else>Don't Show Again</button>
                </div>
                <div class="modalCloseIcon">
                    <button @click.prevent="tutorialsPopupHandle('close')">
                        <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                            <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name:'tutorialsAction',
    data() {
        return {
            tutorialsPopup:{
                popupUrl:'https://www.youtube.com/embed/5r1lqqr5zkU?si=XG0b0UL19url0pk9',
                isOpen:false,
                isDisableBtnLoading:false,
            },

        }
    },
    computed:{

    },
    watch:{

    },
    methods:{
        tutorialsPopupHandle(action){

            if(action == 'open'){

                this.$refs.youtubeVideoFrame.src = this.tutorialsPopup.popupUrl

                this.tutorialsPopup.isOpen = true;

                this.tutorialsPopup.isDisableBtnLoading = false;
                
            }else if (action == 'close'){

                this.$refs.youtubeVideoFrame.src = '';

                this.tutorialsPopup.isOpen = false;

                this.tutorialsPopup.isDisableBtnLoading = false;

            }else if (action == 'jump-to-playlist'){

                const url = 'https://www.youtube.com/embed/5r1lqqr5zkU?si=XG0b0UL19url0pk9';

                window.open(url, '_blank');

            }

        },
        async tutorialsPopupDisableHandle(){

            this.tutorialsPopup.isDisableBtnLoading = true;
            
            try{
                let res = await this.$axios.post('/user/tutorials/disable');
                if(res.data.status_code == '2030'){
                    
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message
                    })

                    this.tutorialsPopupHandle('close');

                    this.$store.state.user.show_tutorial = 0;

                    this.$cookies.set("meri_web_shop.current_user", this.$store.state.user);

                }
            }catch(error){

                this.tutorialsPopupHandle('close');

                if(error.response){

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response.data.message,
                    });
                    
                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }
            }

        }
    },
    mounted(){

        if(this.$store.state.user.show_tutorial == 1){

            this.tutorialsPopupHandle('open');

        }

    },
}
</script>

<style>

</style>