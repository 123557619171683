<template>
  <div class="right-menu shipping-right">
    <div class="db-blue-section pt-0">

      <subHeader pageName="SEO"/>

      <tutorialsAction />

      <div class="db-container">
        <div class="mianBlurBox pt-0">
          <div class="seoDashboardTabsBlock mt-0">
            <div class="tabsBtnRow">
              <button @click.prevent="changeTab('dashboard')" :class="currentTab == 'dashboard' ? 'tab_active' : ''" id="dashboard-btn" >Dashboard</button>
              <button @click.prevent="changeTab('feature')" :class="currentTab == 'feature' ? 'tab_active' : ''" id="features-btn">Features</button>
              <button @click.prevent="changeTab('integration')" :class="currentTab == 'integration' ? 'tab_active' : ''" id="integration-btn">Integrations</button>
              <button @click.prevent="changeTab('webMaster')" :class="currentTab == 'webMaster' ? 'tab_active' : ''" id="webmaster-btn">Web Master Tools</button>
            </div>
            <div class="tabsDataBox" v-if="!pending">
              
              <!-- dashboard tab block starts here-->
              <div class="seoDataDashboard" v-show="currentTab == 'dashboard'" id="seo-content-problems">
                <h2>Problems <span>(0)</span></h2>
                <div class="problemsList">
                  <ul>
                    <p>No Problems Found</p>
                    <li>SEO parameters are undefined.</li>
                    <li>SEO parameters are undefined.</li>
                    <li>SEO parameters are undefined.</li>
                    <li>SEO parameters are undefined.</li>
                    <li>SEO parameters are undefined.</li>
                  </ul>
                </div>
              </div>
              <!-- dashboard tab block ends here-->

              <!-- feature tab block starts here-->
              <div class="seoFeaturesBox" v-show="currentTab == 'feature'" id="available-features">
                <!-- <div class="featuresOptionBox">
                  <div class="foptionTxt">
                    <p>SEO Analysis :</p>
                  </div>
                  <div class="tableFromFieldBox">
                    <div class="actionSwitch">
                      <p>OFF</p>
                      <label class="switch">
                        <input v-model="seo.seo_analysis" type="checkbox" :disabled="tourIsActive">
                        <span class="slider"></span>
                      </label>
                      <p>ON</p>
                    </div>
                  </div>
                </div> -->
                <div class="featuresOptionBox">
                  <div class="foptionTxt">
                    <p>XML Sitemaps :</p>
                  </div>
                  <div class="tableFromFieldBox">
                    <div class="actionSwitch">
                      <p>OFF</p>
                      <label class="switch">
                        <input v-model="seo.xml_sitemaps" type="checkbox" :disabled="tourIsActive">
                        <span class="slider"></span>
                      </label>
                      <p>ON</p>
                    </div>
                  </div>
                </div>
                <div class="featuresOptionBox">
                  <div class="foptionTxt">
                    <p>Canonical :</p>
                  </div>
                  <div class="tableFromFieldBox">
                    <div class="actionSwitch">
                      <p>OFF</p>
                      <label class="switch">
                        <input v-model="seo.canonical" type="checkbox" :disabled="tourIsActive">
                        <span class="slider"></span>
                      </label>
                      <p>ON</p>
                    </div>
                  </div>
                </div>
                <div class="dataSaveBtn" style="text-align: right">
                  <button v-if="!save_btn_loading" @click.prevent="saveChanges('features')" :disabled="tourIsActive">Save Changes</button>
                  <loaderBtn v-else/>
                </div>
              </div>
              <!-- feature tab block ends here-->

              <!-- integration tab block starts here-->
              <div class="integration" v-show="currentTab == 'integration'" id="available-integrations">
                <p style="color: black;text-align: center;margin-bottom: 0px;">Upcoming feature</p>
              </div>
              <!-- integration tab block ends here-->

              <!-- webMaster tab block starts here-->
              <div class="masterTools" v-show="currentTab == 'webMaster'" id="available-webmaster-tools">
                <div class="row">
                  <div class="col-md-6">
                    <div class="formContentBox">
                      <label for="baiduverification">Baidu verification code</label>
                      <input 
                        v-model="seo.baidu_verification_code" 
                        id="baiduverification" 
                        name="baiduverification" 
                        type="text" 
                        class="form-control" 
                        :disabled="tourIsActive"
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="formContentBox">
                      <label for="bingVerification">Bing verification code</label>
                      <input 
                        v-model="seo.bing_verification_code" 
                        id="bingVerification" 
                        name="bingVerification" 
                        type="text" 
                        class="form-control" 
                        :disabled="tourIsActive"
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="formContentBox">
                      <label for="googleVerification">Google verification code</label>
                      <input 
                        v-model="seo.google_verification_code" 
                        id="googleVerification" 
                        name="googleVerification" 
                        type="text" 
                        class="form-control" 
                        :disabled="tourIsActive"
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="formContentBox">
                      <label for="yandexVerification">Yandex verification code</label>
                      <input 
                        v-model="seo.yandex_verification_code" 
                        id="yandexVerification" 
                        name="yandexVerification" 
                        type="text" 
                        class="form-control" 
                        :disabled="tourIsActive"
                      >
                    </div>
                  </div>
                </div>
                <div class="dataSaveBtn" style="text-align: right">
                  <button v-if="!save_btn_loading" @click.prevent="saveChanges('web_master_tools')" :disabled="tourIsActive">Save Changes</button>
                  <loaderBtn v-else/>
                </div>
              </div>
              <!-- webMaster tab block starts here-->

            </div>
            <!-- loader block starts here-->
            <div class="tabsDataBox"  v-else>
              <div class="masterToolsRow" style="justify-content: center">
                <vue-skeleton-loader
                  type="rect"
                  :width="1450"
                  :height="350"
                  animation="fade"
                />
              </div>
            </div> 
            <!-- loader block ends here-->
          </div>
        </div>
      </div>
    </div>
    
    <!--vue tour-->
    <v-tour name="seoGeneralsTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import subHeader from "@/components/subHeader.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
  name: 'Seo',
  metaInfo() {
    return {
      title: 'SEO | General | YeetCommerce',
    };
  },
  data() {
    return {
      currentTab: 'dashboard',
      seo: {
        seo_analysis: false,
        xml_sitemaps: false,
        canonical: false,
        baidu_verification_code: null,
        bing_verification_code: null,
        google_verification_code: null,
        yandex_verification_code: null,
        facebook_pixel_code: null,
      },
      save_btn_loading: false,
      //vue-tour
      tourOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Skip Tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish Tour'
        }
      },
      tourCallbacks: {
        onPreviousStep: this.handlePreviousStep,
        onNextStep: this.handleNextStep,
        // onStart: this.handleTourStart,
        onStop: this.handleTourStop,
        onFinish: this.handleTourFinish,
        onSkip: this.handleTourSkip
      },
      tourSteps:[
        //header steps
        {
          target: '#tour-start-btn',
          content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'bottom'
          }
        },
        {
          target: '#master-search',
          content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
          params: {
            highlight: true,
            enableScrolling: false
          },
        },
        {
          target: '#add-new-dropDown',
          content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#help-link',
          content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#user-options-dropdown',
          content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        //seo generals steps
        {
          target: '#dashboard-btn',
          content: `<strong>Dashboard</strong><br>You can find fundamental details pertaining to SEO on this tab.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement:"bottom"
          }
        },
        {
          target: '#seo-content-problems',
          content: `<strong>SEO Related Issues</strong><br>This section will display any SEO-related concerns.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement:"top"
          }
        },
        {
          target: '#features-btn',
          content: `<strong>Features</strong><br>You can enable or disable SEO-related functionalities through this tab.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement:"bottom"
          }
        },
        {
          target: '#available-features',
          content: `<strong>Features At Your Disposal</strong><br>The features that are currently accessible will be displayed here.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement:"top"
          }
        },
        {
          target: '#integration-btn',
          content: `<strong>Integrations</strong><br>From this tab, you can access the available integration choices.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement:"bottom"
          }
        },
        {
          target: '#available-integrations',
          content: `<strong>Integrations Currently Accessible</strong><br>Here, you can view the integrations currently available.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement:"top"
          }
        },
        {
          target: '#webmaster-btn',
          content: `<strong>Webmaster Tools</strong><br>From this tab, you can access the available webmaster tools.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement:"bottom"
          }
        },
        {
          target: '#available-webmaster-tools',
          content: `<strong>Available Webmaster Tools</strong><br>Here, you can add/update the codes for the available webmaster tools.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement:"top"
          }
        },
      ],
      tourIsActive:false,
    }
  },
  components:{
    subHeader,
    tutorialsAction,
    loaderBtn
  },
  computed:{
    ...mapGetters({
      pending: 'seo_config/isPending',
      seo_config: 'seo_config/data',
      error: 'seo_config/error',
      message: 'seo_config/message',
      logoutIsPending:'logoutIsPending'
    }),
  },
  watch:{
    seo_config:{
      handler: function(value){

        this.seo = value;
        
      },deep: true
    },
  },
  methods:{
    changeTab(tab){

      this.currentTab = tab

    },
    async saveChanges(module){
      
      let formData = new FormData();

      if(module == 'features'){

        formData.append('module',module);
        formData.append('seo_analysis',this.seo.seo_analysis ? 1 : 0);
        formData.append('xml_sitemaps',this.seo.xml_sitemaps ? 1 : 0);
        formData.append('canonical',this.seo.canonical ? 1 : 0);

      }

      if(module == 'web_master_tools'){

        formData.append('module',module);
        formData.append('baidu_verification_code',this.seo.baidu_verification_code);
        formData.append('bing_verification_code',this.seo.bing_verification_code);
        formData.append('google_verification_code',this.seo.google_verification_code);
        formData.append('yandex_verification_code',this.seo.yandex_verification_code);
        formData.append('facebook_pixel_code',this.seo.facebook_pixel_code);

      }

      this.save_btn_loading = true;
      try{
        let res = await this.$axios.post('/seo/generals/update',formData)
        if(res.data.status_code == "1030"){

          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });

          this.$store.dispatch('seo_config/fetch_seo_config_Request');

        }
      }catch(error){

        if(error.response){

          if(error.response.data.error.includes("doesn't support")){

            this.$store.dispatch('seo_config/fetch_seo_config_Request');

            let subscriptionPath = window.location.origin + "/subscription";

            this.$message({
              type: 'error',
              showClose: true,
              dangerouslyUseHTMLString: true,
              message: error.response.data.error 
              + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
            });

          }else{

            this.$message({
              type: 'error',
              showClose: true,
              message: error.response.data.message,
            });

          }

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        this.save_btn_loading = false;

      }
      
    },
    //tour methods
    startseoGeneralsTour(){

      this.$tours.seoGeneralsTour.start();

      this.tourIsActive = true;

    },
    handleNextStep(currentStep){

      if(this.$tours.seoGeneralsTour.currentStep + 1 == 7){

        this.changeTab('feature');

      }

      if(this.$tours.seoGeneralsTour.currentStep + 1 == 9){

        this.changeTab('integration');

      }

      if(this.$tours.seoGeneralsTour.currentStep + 1 == 11){

        this.changeTab('webMaster');

      }

    },
    handlePreviousStep(currentStep){

      if(this.$tours.seoGeneralsTour.currentStep == 7){

        this.changeTab('dashboard');

      }

      if(this.$tours.seoGeneralsTour.currentStep == 9){

        this.changeTab('feature');

      }

      if(this.$tours.seoGeneralsTour.currentStep == 11){

        this.changeTab('integration');

      }

    },
    handleTourStop(){

      this.changeTab('dashboard');

      this.tourIsActive = false;

    },
    handleTourFinish(){

      this.changeTab('dashboard');

      this.tourIsActive = false;

    },
    handleTourSkip(){

      this.changeTab('dashboard');

      this.tourIsActive = false;

    },
  },
  async beforeMount(){

    try{

      if(this.seo_config == null){

        this.$store.dispatch('seo_config/fetch_seo_config_Request');

      }else{

        this.seo = this.seo_config;

      }

    }catch(error){

      this.$message({
        type: "error",
        showClose: true,
        message: error.response ? error.response.data.message : error.message,
      });

    }

  },

  
}
</script>